
import Vue from "vue";
import api from "@/api/manager";
import { SessionActionNames as SSC } from "@/models/SessionStatsConstants";
import date from 'quasar/src/utils/date.js';;
import { startOfDay, endOfDay, subDays, format } from "date-fns";
import { Warn, Success } from "@/services/notif-utils";
export default Vue.extend({
  name: "StatsFilter",
  data() {
    return {
      loading: false,
      loadingUsers: false,
      selectedUser: "",
      calendarToggle: false,
      users: [],
      // dateRange: "" || { from: "", to: "" } || null,
      dateRange: null,
      dateFilter: null,
      dateFrom: "",
      dateTo: "",
      dateFilterToggle: "",
    };
  },
  methods: {
    setDate(day: any) {
      const startDay = new Date();
      const endDate = subDays(startDay, day);
      const formatStartDay = format(startDay, SSC.CLIENT_DATE_MASK);
      const formatEndDay = format(endDate, SSC.CLIENT_DATE_MASK);
      // @ts-expect-error
      this.dateRange = {
        from: formatEndDay,
        to: formatStartDay,
      };
    },
    async loadData() {
      this.loading = true;
      if (this.dateRange == null) {
        return Warn("Select Date Range First");
      }
      if (!this.selectedUser) {
        return Warn("Please choose User");
      }
      // @ts-expect-error
      const date_ = this.formatDateForServer(this.dateRange);
      const data = {
        dateFilter: date_,
        // @ts-expect-error
        userId: this.selectedUser["value"],
      };
      this.$emit("load", data);
    },
    formatDateForServer() {
      const START_OF_DAY = "00:00:00.000000";
      const END_OF_DAY = "23:59:59.999";
      if (this.dateRange == null) {
        return;
      }
      const type_ = typeof this.dateRange;
      if (type_ == "string") {
        const dateObject = {
          from: `${this.dateRange} ${START_OF_DAY}`,
          to: `${this.dateRange} ${END_OF_DAY}`,
        };
        return dateObject;
      }

      if ("from" in this.dateRange && "to" in this.dateRange) {
        const dateObject = {
          from: this.dateRange["from"] + " " + START_OF_DAY,
          to: this.dateRange["to"] + " " + END_OF_DAY,
        };
        return dateObject;
      }
    },
    openCalendar() {
      this.calendarToggle = !this.calendarToggle;
    },
    formatUsersForSelect(data: any) {
      const formattedArray = [] as any;
      data.forEach((element: any) => {
        const data = {
          label: element.operator_name,
          value: element.id,
        };
        formattedArray.push(data);
      });
      return formattedArray;
    },
    loadUsers() {
      this.loadingUsers = true;
      api
        .users()
        .then((response: any) => {
          this.users = this.formatUsersForSelect(response.data.users);
          this.loadingUsers = false;
        })
        .catch((error) => {
          this.loadingUsers = false;
          console.log(error);
        });
    },
  },
  computed: {
    getDateRange(): any {
      if (this.dateRange == null) {
        return "Set Date Range";
      }
      const type_ = typeof this.dateRange;
      if (type_ != "string") {
        // @ts-expect-error
        return `${this.dateRange.from} - ${this.dateRange.to}`;
      }
      return this.dateRange;
    },
    // totalRangeTime():number{

    // }
  },
  created() {
    this.loadUsers();
  },
});
