var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col"},[_c('q-card',{staticClass:"my-card",attrs:{"flat":"","bordered":""}},[_c('q-card-section',{},[_c('pie-chart',{attrs:{"label":"value","legend":"right","download":true,"data":[
          ['Send to admin', _vm.overallStats['send_to_admin']],
          ['Manual Reply', _vm.overallStats['reply']],
          ['Reply With Template', _vm.overallStats['template_reply']],
          ['Delete', _vm.overallStats['delete']],
          ['Send And Delete', _vm.overallStats['send_delete']]
        ]}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }