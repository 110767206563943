
import Vue from "vue";
export default Vue.extend({
  name: "StatsCard",
  props: ["cardData"],
  data() {
    return {
      loading: false,
      stats: {
        totalSendAndDelete: 0,
        totalActions: 0,
        delete: 0,
        sendToAdmin: 0,
        reply: 0
      }
    };
  },
  watch: {
    cardData() {
      if (this.cardData) {
        this.formatData();
      }
    }
  },
  methods: {
    getAvergeTime() {
      // const totalActions = +(this.overallStats["totalActions"])
      // const totalTime
    },
    formatData() {
      this.stats["totalSendAndDelete"] = this.overallStats["send_delete"];
      this.stats["totalActions"] = this.overallStats["totalActions"];
      this.stats["delete"] = this.overallStats["delete"];
      this.stats["reply"] = this.overallStats["reply"];
      this.stats["sendToAdmin"] = this.overallStats["send_to_admin"];
    }
  },
  computed: {
    overallStats(): any {
      const stats = this.cardData as any;
      if (stats) {
        return stats["overallStats"];
      }
      return {};
    },
    totalActions(): number {
      if (this.overallStats) {
        return this.overallStats["totalActions"];
      }
      return 0;
    }
  }
});
