
import Vue from "vue";
export default Vue.extend({
  name: "StatsChart",
  props: ["cardData"],
  data() {
    return {
      loading: false
    };
  },
  computed: {
    overallStats(): any {
      const stats = this.cardData as any;
      if (stats) {
        return stats["overallStats"];
      }
      return {};
    }
  }
});
