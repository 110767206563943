
import Vue from "vue";
import StatsCard from "./StatsCard.vue";
import StatsChart from "./StatsChart.vue";
export default Vue.extend({
  name: "StatComponentsRow",
  components: {
    StatsCard,
    StatsChart
  },
  props: ["stats", "loading"],
  data() {
    return {};
  },
  computed: {
    loadingStat() {
      return this.loading;
    },
    statsData() {
      return this.stats;
    }
  }
});
