
import Vue from "vue";
import { format, formatDistance, differenceInMinutes } from "date-fns";
import { Info } from "@/services/notif-utils";
export default Vue.extend({
  name: "UserSessionStatsTable",
  props: ["stats"],
  data() {
    return {
      loading: false,
      columns: [
        {
          name: "sessionStartDate",
          label: "Session Start Date",
          align: "left",
          field: "session_start_time",
          sortable: true
        },
        {
          name: "session_length",
          align: "center",
          label: "Session Length",
          field: "session_end_time",
          sortable: true
        },

        {
          name: "totalSessionActions",
          label: "Messages Acted On",
          align: "center",
          field: "totalSessionActions"
        },
        {
          name: "totalSessionReplyTemplate",
          label: "Manual Reply",
          align: "center",
          field: "totalSessionReplyTemplate"
        },
        {
          name: "deletedCount",
          label: "Deleted Messages",
          field: "deletedCount",
          align: "center"
        },
        {
          name: "averageMinutes",
          label: "Average Time per Action",
          align: "center"
        }
      ]
    };
  },
  methods: {
    checkForStartTime(row: any) {
      if ("session_start_time" in row) {
        return true;
      }
      return false;
    },
    exportTable() {
      Info("Feature to be developed");
    },
    averageMinutes(totalActions: number, start: string, end: string) {
      const totalMinutes = this.formatDate(start, end);
      console.log(totalMinutes);
      if (totalMinutes > 0 && totalActions > 0) {
        const averageActions = totalMinutes / totalActions;
        return averageActions.toFixed(0);
      }
      if (totalActions < 1) {
        // const averageActions =
        return 0;
      }
      const averageActions = 1 / totalActions;
      return averageActions.toFixed(2);
    },
    formatDate(startTime: string, endTime: string) {
      const d1 = new Date(startTime);
      const d2 = new Date(endTime);
      const nd = differenceInMinutes(d2, d1);
      return nd;
    },
    dateDisplay(date: string) {
      const d = new Date(date);
      const nd = format(d, "EEE, MMM do yyyy");
      return nd;
    }
  },
  computed: {
    sessionStats() {
      const data = this.stats as any;
      if (data) {
        return data["sessionStats"];
      }
      return [];
    }
  }
});
