
import Vue from "vue";
import api from "@/api/manager";
import UserStatsTable from "@/components/UserSessionStatsTable.vue";
import StatsFilter from "@/components/StatsFilters.vue";
import StatComponentsRow from "@/components/StatComponentsRow.vue";
import { Warn, Success } from "@/services/notif-utils";
import { SessionActionNames as SSC } from "@/models/SessionStatsConstants";

export default Vue.extend({
  components: {
    UserStatsTable,
    StatsFilter,
    StatComponentsRow
  },
  name: "Sent",
  data() {
    return {
      loading: false,
      data: null
    };
  },
  methods: {
    loadStats(data: any) {
      this.loading = true;
      api
        .getSessionStats(data)
        .then((response: any) => {
          console.log(response.data);
          this.data = response.data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          Warn("Failed to Load stats");
          this.loading = false;
        });
    }
  }
});
